import {
  COUNTRY_CODES,
  KUWAIT_DEFAULT_COORDS,
  BAHRAIN_DEFAULT_COORDS,
  EGYPT_DEFAULT_COORDS,
  SAUDI_ARABIA_DEFAULT_COORDS,
  UAE_DEFAULT_COORDS,
  QATAR_DEFAULT_COORDS,
  OMAN_DEFAULT_COORDS,
  JORDAN_DEFAULT_COORDS,
  FALLBACK_COORDS,
} from 'common/constants';
import { CoordsType } from 'common/types';
import { logger } from 'common/utils';

const DEFAULT_COORDS: Record<COUNTRY_CODES, CoordsType> = {
  [COUNTRY_CODES.KW]: KUWAIT_DEFAULT_COORDS,
  [COUNTRY_CODES.EG]: EGYPT_DEFAULT_COORDS,
  [COUNTRY_CODES.SA]: SAUDI_ARABIA_DEFAULT_COORDS,
  [COUNTRY_CODES.BH]: BAHRAIN_DEFAULT_COORDS,
  [COUNTRY_CODES.AE]: UAE_DEFAULT_COORDS,
  [COUNTRY_CODES.QA]: QATAR_DEFAULT_COORDS,
  [COUNTRY_CODES.OM]: OMAN_DEFAULT_COORDS,
  [COUNTRY_CODES.JO]: JORDAN_DEFAULT_COORDS,
};

export const getDefaultCoords = (countryCode: string): CoordsType => {
  const coords = DEFAULT_COORDS[countryCode];
  if (!coords) {
    logger.error({ error: 'Unsupported country code: ', extraData: { countryCode } });
    return FALLBACK_COORDS;
  }
  return coords;
};
